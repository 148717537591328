import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import remove from "lodash/remove";
import { getLabelByValue } from "../labels";
import UiDefaults from "./ui-defaults";
import { RESOURCE_TYPE_OBSERVATION } from "../../calculators/constants";
const CERTIFICATIONS_SECTION_NAME = "certifications";

export const parseToolData = response => {
  const result = { _all: {} };
  Object.keys(response).forEach(property => {
    result[property] = {};
    processGroupLabels(response[property]);
    processDataStructure(response[property], result[property]);
    processCertificationSection(result[property], property);
    processGeneralElementsProperty(result[property], result);
  });
  return result;
};

export const processDataStructure = (elementsList = [], elementsMap) => {
  elementsList.forEach(elem => {
    if (elem.children) {
      processDataStructure(elem.children, (elem.children = {}));
    }
    elementsMap[get(elem, "attributes.name")] = elem;
  });
};

export const moveLabelToGroup = element => {
  const childElement = element.children[0];
  element.attributes.fieldInfo = {
    baseContent: childElement.attributes.fieldInfo.baseContent
  };
  childElement.attributes.fieldInfo.baseContent = "";
};

export const setGroupChildrenFlag = element => {
  element.children.forEach(childElement => {
    childElement.attributes.isChild = true;
  });
};

export const processGroupLabels = (elementsList = []) => {
  elementsList.forEach(element => {
    if (element.children?.length) {
      if (element.type === "horizontal_group") {
        moveLabelToGroup(element);
        setGroupChildrenFlag(element);
      } else {
        processGroupLabels(element.children);
      }
    }
  });
};

export const processGeneralElementsProperty = (elementsMap, result) => {
  result._all = { ...result._all, ...elementsMap };
  Object.keys(elementsMap).forEach(key => {
    if (elementsMap[key].children) {
      processGeneralElementsProperty(elementsMap[key].children, result);
    }
  });
};

const processCertificationSection = (propertiesObject, propertyName) => {
  if (CERTIFICATIONS_SECTION_NAME === propertyName) {
    Object.keys(propertiesObject).forEach(elem => {
      const currentProperty = propertiesObject[elem];
      if (
        get(currentProperty, ["attributes", "fieldInfo", "baseContent"], "") ===
        ""
      ) {
        delete propertiesObject[elem];
      }
    });
  }
};

const sortFieldsArray = fields => {
  let fieldsCopy = cloneDeep(fields);
  const reorderedFields = [];
  while (fieldsCopy.length) {
    remove(fieldsCopy, field => {
      if (UiDefaults.checkDependencies(field, reorderedFields)) {
        reorderedFields.push(field);
        return true;
      }
    });
  }
  return reorderedFields;
};

export const parseFieldsArray = (
  fields,
  outputMap,
  ehr,
  snapshotCalcInput,
  ehrMetadata
) => {
  (fields || []).forEach(elem => {
    const name = get(elem, "attributes.name", null);
    if (name) {
      const cpdn = get(elem, "attributes.cpdn", null);
      const { value, ehrEnabledField, link, message } = getInitialValue(
        elem,
        outputMap,
        ehr,
        snapshotCalcInput
      );
      const labDateInfo = getLabDateInfo(elem, outputMap, ehr, ehrMetadata);
      outputMap[name] = {
        label: getLabelByValue(elem, value),
        associatedFields: [],
        isHidden: false,
        value,
        ehrEnabledField,
        labDateInfo
      };
      if (cpdn) {
        outputMap[name]["cpdn"] = cpdn;
      }
      if (link) {
        outputMap[name]["defaultMessageLink"] = link;
      }
      if (message) {
        outputMap[name]["defaultMessage"] = message;
      }
    }
  });
};

const populateAssociatedFields = fieldsData => {
  Object.keys(fieldsData).forEach(key => {
    const cpdn = fieldsData[key].cpdn;
    if (cpdn) {
      fieldsData[key].associatedFields = Object.keys(fieldsData).filter(
        field => {
          return field !== key && fieldsData[field].cpdn === cpdn;
        }
      );
    }
  });
};

export const setAssociatedFieldsValues = fieldsData => {
  Object.keys(fieldsData).forEach(key => {
    fieldsData[key].associatedFields.forEach(name => {
      if (fieldsData[key].value) {
        fieldsData[name].value = fieldsData[key].value;
        fieldsData[name].label = fieldsData[key].label;
      }
    });
  });
};

export const getInitialValue = (
  field,
  outputMap,
  ehr,
  snapshotCalcInput = {}
) => {
  const name = get(field, "attributes.name", null);
  const value = snapshotCalcInput[name];
  return value === undefined
    ? UiDefaults.parseUiDefault(field, outputMap, ehr)
    : { value, ehrEnabledField: false };
};

export const getLabDateInfo = (field, outputMap, ehr, ehrMetadata) => {
  const ehrProp = UiDefaults.parseEhrProp(field, outputMap, ehr);
  if (ehrProp) {
    const metadata = get(ehrMetadata, [ehrProp], null);
    if (metadata) {
      const { resourceType, effectiveDateTime, unit } = metadata;
      if (resourceType === RESOURCE_TYPE_OBSERVATION) {
        const value = get(ehr, [ehrProp], null);
        if (value) {
          return { value, unit, effectiveDateTime, isHidden: false };
        }
      }
    }
  }
};

export const convertFieldsMapToArray = response => {
  return Object.keys(response).map(key => response[key]);
};

export const setMeasureFieldsValues = (fields, result) => {
  (fields || []).forEach(field => {
    const name = get(field, "attributes.name", null);
    const uiDefaultMeasure = get(
      field,
      "attributes.measureInfo.uiDefaultMeasure",
      null
    );
    const labels = get(field, "attributes.measureInfo.labels", []);
    const defaultLabel = labels.find(l => l.value === uiDefaultMeasure);
    if (name && defaultLabel) {
      result[name] = {
        ...result[name],
        measure: {
          value: defaultLabel.value,
          label: defaultLabel.name
        }
      };
    }
  });
};

export const parseFieldsData = (
  fieldsMap,
  ehr,
  snapshotCalcInput,
  ehrMetadata
) => {
  const result = {};
  const reorderedFields = sortFieldsArray(convertFieldsMapToArray(fieldsMap));

  parseFieldsArray(
    reorderedFields,
    result,
    ehr,
    snapshotCalcInput,
    ehrMetadata
  );
  populateAssociatedFields(result);
  setAssociatedFieldsValues(result);
  setMeasureFieldsValues(reorderedFields, result);

  return result;
};

export const parseToolMetadata = ({
  toolSlug,
  pubType,
  slug,
  name,
  version,
  calculator,
  content,
  isFree,
  modified,
  toolTypeDescription,
  toolType,
  toolName,
  toolEligibility,
  toolDescription,
  status
}) => {
  return {
    toolSlug,
    pubType,
    slug,
    version,
    name,
    calculatorName: calculator && calculator.name,
    calculatorVersion: calculator && calculator.version,
    contentName: content && content.name,
    contentVersion: content && content.version,
    isFree: !!isFree, // API response will omit isFree if not free
    toolTypeDescription,
    toolType,
    toolName,
    toolEligibility,
    toolDescription,
    modified,
    status
  };
};
