import get from "lodash/get";
import parser from "../../../helpers/parser";

export const TYPE_ERROR = "TYPE_ERROR";
export const TYPE_WARNING = "TYPE_WARNING";
export const TYPE_CONSTRAINTS_ONLY = "TYPE_CONSTRAINTS_ONLY";
export const TYPE_RULES_ONLY = "TYPE_RULES_ONLY";
export const TYPE_ALL = "TYPE_ALL";

let validationRulesCache = {};
const stringParams = {
  yes: "yes",
  Yes: "yes",
  YES: "yes",
  no: "no",
  No: "no",
  NO: "no"
};

export const requiredValidator = (value, rule, fields, name) => {
  try {
    const result = getRuleResult(name, "required", rule, fields.ui);
    if ((result === true || result === "yes") && !value && value !== 0) {
      return getError();
    } else {
      return [];
    }
  } catch (e) {
    return getIncorrectRuleError("required");
  }
};

export const minValueValidator = (value, rule, fields, name) => {
  try {
    const result = getRuleResult(name, "minValue", rule, fields.ui);
    if (result && (value || value === 0) && Number(result) > Number(value)) {
      return getError();
    } else {
      return [];
    }
  } catch (e) {
    return getIncorrectRuleError("minValue");
  }
};

export const maxValueValidator = (value, rule, fields, name) => {
  try {
    const result = getRuleResult(name, "maxValue", rule, fields.ui);
    if (result && (value || value === 0) && Number(result) < Number(value)) {
      return getError();
    } else {
      return [];
    }
  } catch (e) {
    return getIncorrectRuleError("maxValue");
  }
};

export const expressionValidator = (value, rule, fields, name) => {
  const error_type = rule.type === "error" ? TYPE_ERROR : TYPE_WARNING;
  const { ui } = fields;
  try {
    return getRuleResult(name, rule.expression, rule.expression, ui)
      ? [
          {
            type: error_type,
            message: rule.message
          }
        ]
      : [];
  } catch (e) {
    return getIncorrectRuleError(rule.expression);
  }
};

export const getRuleResult = (name, id, expression, ui) => {
  let parsedRule = get(validationRulesCache, [name, id], null);
  if (!parsedRule) {
    const parsedExpression = parser.parse(expression);
    parsedRule = parsedExpression.toJSFunction("ui", stringParams);
    if (!validationRulesCache[name]) {
      validationRulesCache[name] = {};
    }
    validationRulesCache[name][id] = parsedRule;
  }
  return parsedRule(ui);
};

const getIncorrectRuleError = expression => [
  {
    type: TYPE_ERROR,
    message: `Incorrect validation rule: ${expression}`
  }
];

const getError = () => [
  {
    type: TYPE_ERROR
  }
];

export const emptyValidator = () => [];

export const cleanUpValidatorsCache = () => {
  validationRulesCache = {};
};

export const validatorsMap = {
  required: requiredValidator,
  minValue: minValueValidator,
  maxValue: maxValueValidator,
  expression: expressionValidator,
  empty: emptyValidator
};

export const getValidator = name => {
  return validatorsMap[name] || validatorsMap["empty"];
};

export default getValidator;
